import { useEffect, useState } from 'react'

export const useScrollReveal = (items: any[], initVisibleItems = 12, variable = 2) => {
  const [visibleItems, setVisibleItems] = useState(items.slice(0, initVisibleItems))
  const [itemsToShow, setItemsToShow] = useState(initVisibleItems)
  const [lastScrollY, setLastScrollY] = useState(0)

  useEffect(() => {
    setVisibleItems(items.slice(0, itemsToShow))
  }, [items, itemsToShow])

  const handleScroll = () => {
    const currentScrollY = window.scrollY
    if (currentScrollY > lastScrollY) {
      setLastScrollY(currentScrollY)
      itemsToShow < items.length && setItemsToShow(prev => prev + variable)
    } else {
      setLastScrollY(currentScrollY)
      itemsToShow > initVisibleItems && setItemsToShow(prev => prev - variable)
    }
  }

  useEffect(() => {
    if (items.length > initVisibleItems) {
      window.addEventListener('scroll', handleScroll)
    }
    return () => window.removeEventListener('scroll', handleScroll)
  }, [itemsToShow, items.length])

  return visibleItems
}
