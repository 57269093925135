import React, { useContext, useEffect, useRef, useState } from 'react'
import { CostChartDataResponse, CostEntry, getCostChartData, VendorCostEntries } from '../../api/costs'
import { useTranslation } from 'react-i18next'
import { formatMonthLong, formatMonthShortString, formatNumber, formatToYear, roundNumber } from '../../utils/formats'
import { DomainTuple, Tuple, VictoryAxis, VictoryGroup, VictoryLine, VictoryScatter } from 'victory'
import {
  awsColor,
  azureColor,
  gcpColor,
  gray300,
  gray500,
  primary500,
  primary600,
  primary800,
  small
} from '../../design/constants'
import { DataContainer } from '../shared/containers/DataContainer'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import { CustomTooltip } from '../shared/CustomTooltip'
import { Loading } from '../shared/Loading'
import { FilterOption } from '../shared/filters/FilterSelect'
import { Vendor } from '../../utils/vendors'
import { Currency, CurrencyFlag, CurrencyId, getCurrency, KronaCurrencies } from '../../utils/Currency'
import { GradientText, GrayText, Heading, WhiteText } from '../shared/TextComponents'
import { CostChangeIndicator } from '../shared/indicators/CostChangeIndicator'
import { CountUp } from '../shared/CountUp'
import { ChartZoomToggles, CustomVictoryChart, LineChartHeader } from '../shared/charts/CustomLineChart'
import { MeatballsMenu } from '../shared/MeatballsMenu'
import { RadioButton } from '../shared/buttons/RadioButton'
import { UserInfoContext } from '../../state/context/UserInfoContext'

interface Props {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedServices: string[]
  selectedTags: FilterOption[]
  selectedCurrency: CurrencyId
}

export const CostsChart = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  selectedTags,
  selectedCurrency
}: Props) => {
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const [costChartData, setCostChartData] = useState<CostChartDataResponse | null>(null)
  const [loading, setLoading] = useState(false)
  const [maxAmount, setMaxAmount] = useState(2000)
  const isInitialMount = useRef(true)
  const currentCurrency = useRef(selectedCurrency)
  const minZoomDomain: DomainTuple = [0.5, 13.5]
  const [maxZoomDomain, setMaxZoomDomain] = useState<Tuple<number>>([11.5, 24.5])
  const [zoomDomain, setZoomDomain] = useState<Tuple<number>>(maxZoomDomain)
  const [compareLines, setCompareLines] = useState(true)
  const [displayVendors, setDisplayVendors] = useState<Vendor[]>([Vendor.ALL])
  const [displayedEntries, setDisplayedEntries] = useState<VendorCostEntries[]>([])

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (isInitialMount.current || currentCurrency.current !== selectedCurrency) {
      currentCurrency.current = selectedCurrency
      setCostChartData(null)
      setLoading(true)
      setDisplayedEntries([])
    }
    getCostChartData(
      selectedVendors,
      selectedProjects,
      selectedResourceGroups,
      selectedServices,
      selectedTags.map(tag => ({
        vendor: tag.vendor ?? '',
        tagKey: tag.label,
        tagValues: tag.nest?.map(value => value.value) ?? []
      })),
      selectedCurrency,
      cancelToken.token
    )
      .then(resp => {
        const totals = resp.chartEntries.filter(v => v.vendor === Vendor.ALL)[0] ?? { history: [], forecast: [] }
        const forecastEntries = totals.forecast.length
        const maxZoomX = totals.history.length + (forecastEntries > 1 ? forecastEntries - 1.5 : 0.5)
        setZoomDomain([maxZoomX - 14.5, maxZoomX])
        setMaxZoomDomain([maxZoomX - 14.5, maxZoomX])
        setMaxAmount(Math.max(...totals.history.concat(totals.forecast).map(amount => amount.amount)))
        setCostChartData(resp)
        setDisplayedEntries(resp.chartEntries.filter(v => displayVendors.includes(v.vendor)) ?? [])
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false)
      })

    return () => {
      setLoading(false)
      isInitialMount.current = true
      cancelToken.cancel()
    }
  }, [
    displayVendors,
    selectedVendors,
    selectedProjects,
    selectedResourceGroups,
    selectedServices,
    selectedTags,
    selectedCurrency,
    createCancelToken,
    handleError
  ])

  useEffect(() => {
    !displayVendors.length && setDisplayVendors([Vendor.ALL])
    costChartData &&
      costChartData.chartEntries.length > 0 &&
      setDisplayedEntries(costChartData?.chartEntries.filter(entry => displayVendors.includes(entry.vendor)) ?? [])
  }, [displayVendors, costChartData])

  const currency = getCurrency(selectedCurrency)
  const totalHistory = costChartData?.chartEntries.find(v => v.vendor === Vendor.ALL)?.history ?? []
  const currYearDates = totalHistory.filter((_, index) => index >= 12).map(item => item.date)
  const legendData = [{ name: t('costs.costsChart.costsToDate'), symbol: { fill: primary500 } }]
  const getHistoryCompare = (costHistory: CostEntry[]) =>
    costHistory
      .filter((_, index) => index < new Date().getMonth() + 1)
      .map((item, index) => ({
        ...item,
        date: currYearDates.find((_, i) => i === index)
      }))
  compareLines && legendData.unshift({ name: t('costs.costsChart.lastYear'), symbol: { fill: primary800 } })

  if (loading || displayedEntries.length === 0)
    return (
      <DataContainer flexCol={true} looseSpacing={true}>
        <Heading>{t('costs.costsChart.title')}</Heading>
        <Loading height={64} paddingY={'5rem'} />
      </DataContainer>
    )

  return (
    <div id={'qa-costs-chart-container'}>
      <DataContainer flexCol={true} looseSpacing={true}>
        <ChartHeader
          data={costChartData}
          currency={currency}
          setCompareLines={setCompareLines}
          compareLines={compareLines}
          displayVendors={displayVendors}
          setDisplayVendors={setDisplayVendors}
          setZoomDomain={setZoomDomain}
          zoomDomain={zoomDomain}
          minZoomDomain={minZoomDomain}
          maxZoomDomain={maxZoomDomain}
        />
        <CustomVictoryChart
          domain={[0, maxAmount > 1 ? maxAmount : 1]}
          zoomDomain={zoomDomain}
          tooltip={<CostsTooltip />}
          tooltipLabel={({ datum }) => datum.amount}
          legendData={legendData}
        >
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            style={{
              axis: { stroke: 'none' },
              axisLabel: {},
              grid: { stroke: gray500 },
              ticks: { size: 0 },
              tickLabels: {
                fontSize: small,
                fontWeight: 200,
                padding: 20,
                fill: gray300
              }
            }}
          />
          <VictoryAxis
            style={{
              axis: { stroke: 'none' },
              axisLabel: {},
              grid: { stroke: 'none' },
              ticks: { size: 0 },
              tickLabels: {
                letterSpacing: '1px',
                fontSize: small,
                fontWeight: 200,
                padding: 20,
                fill: gray300
              }
            }}
            tickFormat={t => formatMonthShortString(t)}
          />
          <VictoryAxis
            style={{
              axis: { stroke: 'none' },
              tickLabels: {
                letterSpacing: '1px',
                fontSize: '12px',
                fontWeight: 200,
                padding: 40,
                fill: gray300
              }
            }}
            tickFormat={tick => (tick?.toString().includes('01-') ? formatToYear(tick) : '')}
          />
          {displayedEntries?.map(v => (
            <VictoryGroup key={v.vendor}>
              <VictoryLine
                key={v.vendor}
                data={v.history}
                x={'date'}
                y={'amount'}
                style={{
                  data: {
                    stroke:
                      v.vendor === Vendor.AWS
                        ? awsColor
                        : v.vendor === Vendor.AZURE
                          ? azureColor
                          : v.vendor === Vendor.GCP
                            ? gcpColor
                            : primary500,
                    strokeWidth: 1
                  }
                }}
              />
              <VictoryScatter
                data={v.history}
                x={'date'}
                y={'amount'}
                style={{
                  data: {
                    fill:
                      v.vendor === Vendor.AWS
                        ? awsColor
                        : v.vendor === Vendor.AZURE
                          ? azureColor
                          : v.vendor === Vendor.GCP
                            ? gcpColor
                            : primary500
                  }
                }}
                size={({ active }) => (active ? 4 : 3)}
              />
            </VictoryGroup>
          ))}
          {compareLines &&
            displayedEntries.map(v => (
              <VictoryGroup key={v.vendor}>
                <VictoryLine
                  data={getHistoryCompare(v.history)}
                  x={'date'}
                  y={'amount'}
                  style={{
                    data: {
                      opacity: 0.4,
                      stroke:
                        v.vendor === Vendor.AWS
                          ? awsColor
                          : v.vendor === Vendor.AZURE
                            ? azureColor
                            : v.vendor === Vendor.GCP
                              ? gcpColor
                              : primary600,
                      strokeWidth: 1
                    }
                  }}
                />
                <VictoryScatter
                  data={getHistoryCompare(v.history)}
                  x={'date'}
                  y={'amount'}
                  style={{
                    data: {
                      opacity: 0.4,
                      fill:
                        v.vendor === Vendor.AWS
                          ? awsColor
                          : v.vendor === Vendor.AZURE
                            ? azureColor
                            : v.vendor === Vendor.GCP
                              ? gcpColor
                              : primary600
                    }
                  }}
                  size={({ active }) => (active ? 4 : 3)}
                />
              </VictoryGroup>
            ))}
          {displayedEntries?.map(v => (
            <VictoryGroup key={v.vendor}>
              <VictoryLine
                data={v.initForecast}
                x={'date'}
                y={'amount'}
                style={{
                  data: {
                    opacity: 0.8,
                    stroke:
                      v.vendor === Vendor.AWS
                        ? awsColor
                        : v.vendor === Vendor.AZURE
                          ? azureColor
                          : v.vendor === Vendor.GCP
                            ? gcpColor
                            : primary600,
                    strokeDasharray: '2',
                    strokeWidth: 1
                  }
                }}
              />
              <VictoryScatter
                data={v.initForecast}
                x={'date'}
                y={'amount'}
                style={{
                  data: {
                    opacity: 0.8,
                    fill:
                      v.vendor === Vendor.AWS
                        ? awsColor
                        : v.vendor === Vendor.AZURE
                          ? azureColor
                          : v.vendor === Vendor.GCP
                            ? gcpColor
                            : primary600
                  }
                }}
                size={({ active }) => (active ? 4 : 3)}
              />
            </VictoryGroup>
          ))}
          {displayedEntries?.map(v => (
            <VictoryGroup key={v.vendor}>
              <VictoryLine
                data={v.forecast}
                x={'date'}
                y={'amount'}
                style={{
                  data: {
                    stroke:
                      v.vendor === Vendor.AWS
                        ? awsColor
                        : v.vendor === Vendor.AZURE
                          ? azureColor
                          : v.vendor === Vendor.GCP
                            ? gcpColor
                            : primary600,
                    strokeDasharray: '2',
                    strokeWidth: 1
                  }
                }}
              />
              <VictoryScatter
                data={v.forecast}
                x={'date'}
                y={'amount'}
                style={{
                  data: {
                    fill:
                      v.vendor === Vendor.AWS
                        ? awsColor
                        : v.vendor === Vendor.AZURE
                          ? azureColor
                          : v.vendor === Vendor.GCP
                            ? gcpColor
                            : primary600
                  }
                }}
                size={({ active }) => (active ? 4 : 3)}
              />
            </VictoryGroup>
          ))}
        </CustomVictoryChart>
      </DataContainer>
    </div>
  )
}

interface ChartHeaderProps {
  data: CostChartDataResponse | null
  currency: Currency
  setCompareLines: (value: boolean) => void
  compareLines: boolean
  displayVendors: Vendor[]
  setDisplayVendors: (vendors: Vendor[]) => void
  setZoomDomain: (value: Tuple<number>) => void
  zoomDomain: Tuple<number>
  minZoomDomain: Tuple<number>
  maxZoomDomain: Tuple<number>
}

const ChartHeader = ({
  data,
  currency,
  setCompareLines,
  compareLines,
  displayVendors,
  setDisplayVendors,
  setZoomDomain,
  zoomDomain,
  minZoomDomain,
  maxZoomDomain
}: ChartHeaderProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)

  const handleSelect = (vendor: Vendor) => {
    displayVendors.includes(vendor)
      ? setDisplayVendors(displayVendors.filter(v => v !== vendor))
      : setDisplayVendors([...displayVendors, vendor])
  }

  const vendorOptions = userSettings.visibleVendors.map(vendor => ({
    isCheckbox: true,
    group: 'vendors',
    label: t(`vendors.${vendor}.short`),
    clickHandler: () => handleSelect(vendor),
    checked: displayVendors.includes(vendor)
  }))

  const options = [
    {
      isRadio: true,
      type: 'secondary',
      label: t('costs.costsChart.compareLastYear'),
      clickHandler: () => setCompareLines(!compareLines),
      checked: compareLines
    },
    {
      isCheckbox: true,
      group: 'vendors',
      label: t('vendors.ALL.short'),
      clickHandler: () => handleSelect(Vendor.ALL),
      checked: displayVendors.includes(Vendor.ALL),
      disabled: displayVendors.length === 1 && displayVendors.includes(Vendor.ALL)
    },
    ...vendorOptions
  ]

  return (
    <LineChartHeader
      heading={t('costs.costsChart.title')}
      subheading={
        data && (
          <>
            <WhiteText className={'first-letter:capitalize'}>
              {t('costs.costsChart.currentTotal')}
              <ChartHighlight className={'text-125'}>
                <CountUp id={'qa-costs-total-history-costs'} countTo={roundNumber(data.currentTotal, 0)} />
                <span className={'inline-flex text-112 gap-2 items-center'}>
                  {currency.symbol}
                  {KronaCurrencies.some(id => id === currency.id) && <CurrencyFlag currencyId={currency.id} size={4} />}
                </span>
              </ChartHighlight>
            </WhiteText>
            <div className={'flex gap-1'}>
              <CostChangeIndicator id={'qa-costs-change-from-last-year'} change={data.changeFromLastYear} />
              {data.changeFromLastYear && (
                <GrayText lowercase={true}>
                  {data.changeFromLastYear === 0
                    ? t('costs.costsChart.noChange')
                    : data.changeFromLastYear > 0
                      ? t('costs.costsChart.increase')
                      : t('costs.costsChart.decrease')}
                </GrayText>
              )}
            </div>
            <GrayText className={'text-90 first-letter:capitalize'}>
              {t('costs.costsChart.lastYearCurrent', {
                month: formatMonthLong(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), true)
              })}
              <ChartHighlight>
                <CountUp id={'qa-costs-last-year-history-costs'} countTo={roundNumber(data.lastYearCurrent, 0)} />
                <span className={'inline-flex gap-2 items-center'}>{currency.symbol}</span>
              </ChartHighlight>
            </GrayText>
          </>
        )
      }
      actions={
        <>
          {userSettings.visibleVendors.length > 1 ? (
            <MeatballsMenu options={options} />
          ) : (
            <RadioButton
              label={t('costs.costsChart.compareLastYear')}
              onChange={() => setCompareLines(!compareLines)}
              checked={compareLines}
              type={'secondary'}
            />
          )}
          <ChartZoomToggles
            setDomain={setZoomDomain}
            domain={zoomDomain}
            minDomain={minZoomDomain}
            maxDomain={maxZoomDomain}
          />
        </>
      }
    />
  )
}

const ChartHighlight = styled(GradientText)`
  ${tw`inline-flex pl-2 gap-1 font-black from-primary-200 to-primary-300`}
`

interface TooltipProps {
  active?: boolean
  x?: number
  y?: number
  datum?: CostEntry
}

const CostsTooltip = ({ active, x, y, datum }: TooltipProps) => {
  if (!active) return null
  const width = 140
  const height = 54
  // @ts-ignore
  const posX = x - width / 2
  // @ts-ignore
  const posY = y - height - 1
  if (!datum) return null
  return (
    <g>
      <foreignObject x={posX} y={posY} width={width} height={height}>
        <CustomTooltip pointerDirection={'down'} showIcon={false}>
          {`${formatNumber(datum['amount'])} ${getCurrency(datum['currency']).symbol}`}
        </CustomTooltip>
      </foreignObject>
    </g>
  )
}
