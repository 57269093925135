import React from 'react'
import { useTranslation } from 'react-i18next'
import { IBProject, IBResource, IBType } from '../../../api/infra'
import { TreeBranch } from '../../shared/TreeBranch'
import { DataTableCell, DataTableLabel, DataTableRow, DataTableValue } from '../../shared/DataTableSharedComponents'
import { ClipboardCopy } from '../../shared/ClipboardCopy'
import { Vendor } from '../../../utils/vendors'
import { useScrollReveal } from '../../../hooks/useScrollReveal'

interface InfraRegionBreakdownProps {
  vendor: Vendor
  projects: IBProject[]
  region: string
}

export const InfraRegionBreakdown = ({ vendor, projects, region }: InfraRegionBreakdownProps) => {
  const { t } = useTranslation()

  return (
    <>
      <DataTableRow isHeader={true} styles={'xl:grid-cols-8'}>
        <DataTableCell styles={'col-span-3 ml-[68px]'}>{t('common.name')}</DataTableCell>
        <DataTableCell styles={'ml-[34px]'}>{t('common.count')}</DataTableCell>
        <DataTableCell styles={'ml-6'}>{t('common.identifier')}</DataTableCell>
      </DataTableRow>
      {projects.map(project => (
        <ProjectComponent
          key={`${vendor}-${region}-${project.id}`}
          vendor={vendor}
          region={region}
          project={project}
          depth={1}
        />
      ))}
    </>
  )
}

interface ProjectComponentProps {
  vendor: Vendor
  region: string
  project: IBProject
  isResourceGroup?: boolean
  depth: number
}

export const ProjectComponent = ({
  vendor,
  region,
  project,
  isResourceGroup = false,
  depth
}: ProjectComponentProps) => {
  return (
    <TreeBranch
      depth={depth}
      headerData={<ProjectRow data={project} />}
      contentData={
        project.resourceGroups.length > 0
          ? project.resourceGroups.map((resourceGroup, index) => (
              <ResourceGroupComponent
                key={`${vendor}-${region}-${project.id}-${resourceGroup}-${index}`}
                vendor={vendor}
                region={region}
                project={resourceGroup}
                depth={depth + 1}
              />
            ))
          : project.resources.map((resourceType, index) => (
              <ResourceTypeComponent
                project={project.id}
                resourceType={resourceType}
                key={`${vendor}-${region}-${project.name}-${resourceType.type}-${index}`}
                isResourceGroup={isResourceGroup}
                depth={depth + 1}
              />
            ))
      }
    />
  )
}

interface ProjectComponentRow {
  data: IBProject
}

const ProjectRow = ({ data }: ProjectComponentRow) => {
  const getTotalResources = (project: IBProject) =>
    project.resourceGroups.length
      ? project.resourceGroups.reduce(
          (acc, curr) => acc + curr.resources.reduce((acc, curr) => acc + curr.resources.length, 0),
          0
        )
      : project.resources.reduce((acc, curr) => acc + curr.resources.length, 0)

  return <BreakdownDataRow name={data.name} count={getTotalResources(data)} id={data.id} />
}

const ResourceGroupComponent = ({ project, region, vendor, depth }: ProjectComponentProps) => {
  return (
    <TreeBranch
      depth={depth}
      headerData={<ResourceRow data={project} resourcesMargin={'xl:-ml-6'} idMargin={'xl:-ml-[18px]'} />}
      contentData={project.resources.map((resourceType, index) => (
        <ResourceTypeComponent
          project={project.id}
          resourceType={resourceType}
          key={`${vendor}-${region}-${project.name}-${resourceType.type}-${index}`}
          isResourceGroup={true}
          depth={depth + 1}
        />
      ))}
    />
  )
}

interface ResourceTypeComponentProps {
  project: string
  resourceType: IBType
  isResourceGroup: boolean
  depth: number
}

const ResourceTypeComponent = ({ resourceType, isResourceGroup, depth }: ResourceTypeComponentProps) => {
  const { t } = useTranslation()
  const visibleResources: IBResource[] = useScrollReveal(resourceType.resources)

  return (
    <TreeBranch
      depth={depth}
      headerData={
        <ResourceRow
          data={resourceType}
          resourcesMargin={isResourceGroup ? 'xl:-ml-12' : 'xl:-ml-5'}
          idMargin={isResourceGroup ? 'xl:-ml-9' : 'xl:-ml-[18px]'}
        />
      }
      contentData={visibleResources.map((resource, index) => (
        <TreeBranch
          key={`${resource.id}-${resource.name}-${index}`}
          depth={depth + 1}
          headerData={
            <BreakdownDataRow
              name={resource.name ? resource.name : t('common.unknown')}
              id={resource.id ? resource.id : t('common.unknown')}
              idMargin={isResourceGroup ? 'xl:-ml-[30px]' : 'xl:-ml-3.5'}
            />
          }
          contentData={false}
        />
      ))}
    />
  )
}

interface CollapsibleRowProps {
  data: IBProject | IBType
  resourcesMargin?: string
  idMargin?: string
  isResourceGroup?: boolean
}

const ResourceRow = ({ data, resourcesMargin, idMargin }: CollapsibleRowProps) => {
  const isResourceType = 'type' in data

  const getTotalResources = (project: IBProject) =>
    project.resourceGroups.length
      ? project.resourceGroups.reduce(
          (acc, curr) => acc + curr.resources.reduce((acc, curr) => acc + curr.resources.length, 0),
          0
        )
      : project.resources.reduce((acc, curr) => acc + curr.resources.length, 0)

  return (
    <BreakdownDataRow
      name={isResourceType ? data.type : data.name}
      count={isResourceType ? data.resources.length : getTotalResources(data)}
      id={isResourceType ? undefined : data.id}
      countMargin={resourcesMargin}
      idMargin={idMargin}
    />
  )
}

interface DataRowProps {
  name?: string
  count?: number
  id?: string
  countMargin?: string
  idMargin?: string
}

const BreakdownDataRow = ({ name, count, id, countMargin, idMargin }: DataRowProps) => {
  const { t } = useTranslation()

  return (
    <DataTableRow styles={'xl:grid-cols-8'}>
      <DataTableCell styles={'xl:col-span-3'}>
        <DataTableLabel>{t('common.name')}</DataTableLabel>
        <DataTableValue>{name ? name : t('common.unknown')}</DataTableValue>
      </DataTableCell>
      {count && (
        <DataTableCell styles={`${countMargin}`}>
          <DataTableLabel>{t('common.count')}</DataTableLabel>
          <DataTableValue>{count}</DataTableValue>
        </DataTableCell>
      )}
      {id && (
        <DataTableCell styles={`${idMargin} xl:col-start-5 xl:col-span-4`}>
          <DataTableLabel>{t('common.identifier')}</DataTableLabel>
          <DataTableValue className={'break-all text-right xl:text-left'}>
            <ClipboardCopy copyText={id} limit={40} />
          </DataTableValue>
        </DataTableCell>
      )}
    </DataTableRow>
  )
}
